<template>
    <main class="category gift-category page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">

        <div v-if="supplierData" class="category__top-wrapper">
            <h1 class="title category__title">{{ supplierData.name }}</h1>
            <div class="gift-category__image">
                <img class="gift-category__image" :src="supplierData.light_logo" alt="">
            </div>
        </div>

        <section class="category__switch ">
            <template v-for="(productCategory) in supplierProductCategories">
                <a 
                    :key="'pc_' + productCategory.code" class="link category__link"
                    :class="{'category__link_active': productCategory.code === currentSupplierProductCategory}"
                    v-if="productCategory.data && productCategory.data.length > 0"
                    @click="currentSupplierProductCategory = productCategory.code"
                >
                    {{ productCategory.label }}
                </a>
            </template>
        </section>

        <div v-show="currentSupplierProductCategoryItems && currentSupplierProductCategoryItems.length > 0" class="category__container">

            <div v-for="(product_item) in currentSupplierProductCategoryItems" :key="'pc_pi_' + product_item.id" class="category__unit ">

                <div class="category__bg gift-category__unit-bg_color_pink" :style="{'background-color': supplierData ? supplierData.chosen_card_background_color : 'initial'}">

                    <p v-if="!product_item.add_vat" class="no-vat-remark">
                        {{ $t('COMMUNICATION.PRODUCT_WITH_NO_VAT') }}
                    </p>

                    <CategoryShare></CategoryShare>

                    <div class="category__star"  @click="changeFavorite(product_item.id, !product_item.favorite)">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="25"
                            viewBox="0 0 26 25"
                        >
                            <path
                                :style="{ fill: product_item.favorite ? supplierData.favorite_star_color : '#fff'}"
                                d="M32.158.857,28.97,7.321l-7.133,1.04a1.563,1.563,0,0,0-.864,2.666l5.161,5.029-1.221,7.1A1.561,1.561,0,0,0,27.178,24.8l6.381-3.354L39.941,24.8a1.563,1.563,0,0,0,2.265-1.645l-1.221-7.1,5.16-5.029a1.563,1.563,0,0,0-.864-2.666l-7.133-1.04L34.961.857a1.564,1.564,0,0,0-2.8,0Z"
                                transform="translate(-20.5 0.013)"
                            />
                        </svg>
                    </div>

                    <img 
                        src="/assets/img/icons/info.svg" 
                        alt="" 
                        class="infor-icon"
                        @click="openInforPopup(product_item)"
                        v-if="product_item.show_additional_details"
                    >

                    <p class="gift-category__product-name" :style="{'color': supplierData ? supplierData.font_color : 'initial'}">{{ product_item.name }}</p>
                    <div class="gift-category__unit-caption">
                        <!-- <div class="gift-category__price gift-category__price_color_black">
                            <b class="gift-category__price-value">{{ ' ' + product_item.price + ' ' }}</b>
                            <b class="gift-category__current">{{ $t("COMMON.NIS") }}</b>
                        </div> -->

                        <div class="gift-category__product-line gift-category__product-line_color_dark" :style="{'background-color': supplierData ? supplierData.logo_background_color : 'initial'}">
                            <p class="fs20" :style="{'color': supplierData ? supplierData.font_color : 'initial'}">Giftcard</p>
                            <div v-if="supplierData" class="gift-category__img-wrapper">
                                <img class="gift-category__img" :src="supplierData.image" alt="">
                            </div>
                        </div>
                    </div>
                    <p class="fs16 valid-day">{{ $t('COMMUNICATION.VALID_ON_DAYS') }}: {{ product_item.usage_time_limit }}</p>

                </div>

                <div class="category__info">
                    <p class="text category__text" v-html="product_item.description"></p>
                    <div class="category__price-wrapper ">
                        <span class="category__price">{{ $t("COMMON.PRICE") }}:</span>
                        <b class="category__price-value ">{{ ' ' + product_item.price + ' ' }}</b>
                        <b class="category__current ">{{ $t("COMMON.NIS") }}</b>
                    </div>
                    <router-link :to="{name: 'GiftCardProduct', params: {id: product_item.id, supplier_id: product_item.supplier_id }}" tag="a" class="link category__button" exact>{{ $t("COMMON.TIME") }}</router-link>
                </div>
            </div>
        </div>

        <section v-if="notCurrentSupplierProductCategoryItems && notCurrentSupplierProductCategoryItems.length > 0" class="category__more">

            <h2 class="title category__title-h2">{{ $t("GIFTCARDSUP.ADDITIONAL_PRODUCTS") }}</h2>

            <div class="category__wrapper">
                <div v-for="(product_item) in notCurrentSupplierProductCategoryItems" :key="'pi_' + product_item.id" class="category__unit ">

                    <div class="category__bg gift-category__unit-bg_color_orange" :style="{'background-color': supplierData ? supplierData.product_card_background_color : 'initial'}">

                        <p v-if="!product_item.add_vat" class="no-vat-remark">
                            {{ $t('COMMUNICATION.PRODUCT_WITH_NO_VAT') }}
                        </p>

                        <CategoryShare></CategoryShare>

                        <div class="category__star" @click="changeFavorite(product_item.id, !product_item.favorite)">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="25"
                                viewBox="0 0 26 25"
                            >
                                <path
                                    :style="{ fill: product_item.favorite ? supplierData.favorite_star_color : '#fff'}"
                                    d="M32.158.857,28.97,7.321l-7.133,1.04a1.563,1.563,0,0,0-.864,2.666l5.161,5.029-1.221,7.1A1.561,1.561,0,0,0,27.178,24.8l6.381-3.354L39.941,24.8a1.563,1.563,0,0,0,2.265-1.645l-1.221-7.1,5.16-5.029a1.563,1.563,0,0,0-.864-2.666l-7.133-1.04L34.961.857a1.564,1.564,0,0,0-2.8,0Z"
                                    transform="translate(-20.5 0.013)"
                                />
                            </svg>
                        </div>

                        <img 
                            src="/assets/img/icons/info.svg" 
                            alt="" 
                            class="infor-icon"
                            @click="openInforPopup(product_item)"
                            v-if="product_item.show_additional_details"
                        >

                        <p class="gift-category__product-name" :style="{'color': supplierData ? supplierData.font_color : 'initial'}">{{ product_item.name }}</p>
                        <div class="gift-category__unit-caption">
                            <!-- <div class="gift-category__price gift-category__price_color_black-2">
                                <b class="gift-category__price-value">{{ ' ' + product_item.price + ' ' }}</b>
                                <b class="gift-category__current">{{ $t("COMMON.NIS") }}</b>
                            </div> -->
                            <div class="gift-category__product-line gift-category__product-line_color_dark" :style="{'background-color': supplierData ? supplierData.logo_background_color : 'initial'}">
                                <p class="fs20" :style="{'color': supplierData ? supplierData.font_color : 'initial'}">Giftcard</p>
                                <div v-if="supplierData" class="gift-category__img-wrapper">
                                    <img class="gift-category__img" :src="supplierData.image" alt="">
                                </div>
                            </div>
                        </div>
                        <p class="fs16 valid-day">{{ $t('COMMUNICATION.VALID_ON_DAYS') }}: {{ product_item.usage_time_limit }}</p>
                    </div>

                    <div class="category__info">
                        <p class="text category__text" v-html="product_item.description"></p>
                        <div class="category__price-wrapper ">
                            <span class="category__price">{{ $t("COMMON.PRICE") }}:</span>
                            <b class="category__price-value ">{{ ' ' + product_item.price + ' ' }}</b>
                            <b class="category__current ">{{ $t("COMMON.NIS") }}</b>
                        </div>
                        <router-link :to="{name: 'GiftCardProduct', params: {id: product_item.id, supplier_id: product_item.supplier_id }}" tag="a" class="link category__button" exact>{{ $t("COMMON.TIME") }}</router-link>
                    </div>
                </div>
            </div>
        </section>
        <loading
            :active.sync="loading"
            :can-cancel="false"
            :is-full-page="true"
        ></loading>
        <InforPopup :inforData="inforData" :modal_key="modal_key"></InforPopup>
    </main>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import appConfig from '../../appConfig'
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import CategoryShare from '../../components/communication/CategoryShare.vue'
import InforPopup from '../../components/communication/InforPopup.vue'

export default {
    name: 'GiftCardSupplier',
    components: {
        Loading,
        CategoryShare,
        InforPopup
    },
    data() {
        return {
            currentSupplierProductCategory: null,
            modal_key: +new Date(),
            inforData: ""
        }
    },
    mounted() {
    },
    computed: {
        ...mapState({
            loading: (state) => state.supplier.loading,
            supplier_items: state => state.supplier.supplier_items,
            suppliers: state => state.supplier.suppliers,
            suppliers_products: state => state.supplier.suppliers_products,
            product_tags: (state) => state.supplier.product_tags,
        }),
        supplierId() {
            return this.$route.params.id
        },
        supplierData() {
            let supplierDataFromSuppliersItemsData = null;
            if (this.supplier_items) {
                for (let supplier_type in this.supplier_items) {
                    for (let suppliers_type_item of this.supplier_items[supplier_type]) {
                        if (suppliers_type_item.id == this.supplierId) {
                            supplierDataFromSuppliersItemsData = {...suppliers_type_item};
                            break;
                        }
                    }
                }
            }

            let supplierDataFromSuppliersData = null;
            if (this.suppliers && this.supplierId in this.suppliers) {
                supplierDataFromSuppliersData = {...this.suppliers[this.supplierId]}
            }

            let supplierData = supplierDataFromSuppliersData ? supplierDataFromSuppliersData : supplierDataFromSuppliersItemsData
            return supplierData ? supplierData : null
        },
        supplierProductsAll() {
            let supplierProducts = []
            if (this.suppliers_products && this.supplierId in this.suppliers_products) {
                let supplerProductsByType = this.suppliers_products[this.supplierId]

                if (supplerProductsByType) {
                    for (let supplier_product_type in supplerProductsByType) {
                        for (let supplier_product of supplerProductsByType[supplier_product_type]) {
                            supplierProducts.push(supplier_product)
                        }
                    }
                }
            }

            return supplierProducts ? supplierProducts : []
        },
        supplierProductCategories() {
            let supplierProductCategories = [];
            let favoriteCategoryCode = 'favorite';
            let favoriteSupplierProducts = this.filterSupplierProducts(favoriteCategoryCode, true);
            if (favoriteSupplierProducts && favoriteSupplierProducts.length > 0) {
                supplierProductCategories.push({
                    label: this.$t('DASHBOARD.FAVORITES'),
                    code: favoriteCategoryCode,
                    data: favoriteSupplierProducts
                });

                this.setCurrentSupplierProductCategoryIfEmpty(favoriteCategoryCode);
            } else {
                this.unsetCurrentSupplierProductCategoryIfEqual(favoriteCategoryCode);
            }

            for (let tag_index in this.product_tags) {
                let tag = this.product_tags[tag_index];
                let filteredProductsByTag = this.filterSupplierProducts('tag', tag.id);

                if (filteredProductsByTag && filteredProductsByTag.length > 0) {
                    supplierProductCategories.push({
                        label: tag.name, // add languages on the backend or translations
                        code: tag.id,
                        data: filteredProductsByTag
                    });

                    this.setCurrentSupplierProductCategoryIfEmpty(tag.id);
                } else {
                    this.unsetCurrentSupplierProductCategoryIfEqual(tag.id);
                }
            }

            return supplierProductCategories;
        },
        currentSupplierProductCategoryItems() {
            let selectedProductsCategoryItems = [];
            if (this.currentSupplierProductCategory
                && this.supplierProductCategories
                && this.supplierProductCategories.length > 0) {
                let selectedProductsCategory = this.supplierProductCategories.find(spc => spc.code === this.currentSupplierProductCategory);

                if (selectedProductsCategory) {
                    selectedProductsCategoryItems = selectedProductsCategory.data || [];
                }
            }

            return selectedProductsCategoryItems;
        },
        notCurrentSupplierProductCategoryItems() {
            let notCurrentSupplierProductCategoryItems = [];

            if (this.supplierProductsAll && this.currentSupplierProductCategoryItems) {
                let currentSupplierProductCategoryItemsIds = this.currentSupplierProductCategoryItems.map(p => p.id);
                notCurrentSupplierProductCategoryItems = this.supplierProductsAll.filter(
                    sp => !currentSupplierProductCategoryItemsIds.includes(sp.id)
                );
            }

            return notCurrentSupplierProductCategoryItems;
        },
    },
    created() {
        this.getSupplier({'supplier_id': this.supplierId})
        this.getSupplierProducts({'supplier_id': this.supplierId})
        this.getProductTags()
    },
    methods: {
        ...mapActions('supplier', {
            getSupplier: 'getSupplier',
            getSupplierProducts: 'getSupplierProducts',
            getProductTags: 'getProductTags',
            setFavoriteProduct: 'setFavoriteProduct'
        }),
        getApiURL() {
            return appConfig.apiUrl
        },
        setCurrentSupplierProductCategoryIfEmpty(category) {
            this.currentSupplierProductCategory = this.currentSupplierProductCategory || category;
        },
        unsetCurrentSupplierProductCategoryIfEqual(category) {
            this.currentSupplierProductCategory = this.currentSupplierProductCategory === category
                ? null : this.currentSupplierProductCategory;
        },
        filterSupplierProducts(field, value) {
            let filteredSupplierProducts = [];
            if (!value || !field || !this.supplierProductsAll) {
                return filteredSupplierProducts;
            }

            if (field === 'tag') {
                filteredSupplierProducts = this.supplierProductsAll.filter(
                    (product) =>
                        product.hasOwnProperty('tags') &&
                        product.tags.length &&
                        product.tags.includes(value),
                );
            } else {
                filteredSupplierProducts = this.supplierProductsAll.filter(
                    (product) => product.hasOwnProperty(field) && product[field] == value,
                );
            }

            return filteredSupplierProducts;
        },
        changeFavorite(id, value) {
            this.setFavoriteProduct({product_id: id, supplier_id: this.supplierId, favorite: value});
        },
        /* <!-- ------------information popup-------------- --> */
        openInforPopup(item) {
            if (item.additional_details=="" || !item.additional_details) {
                this.inforData = item.additional_details_default;
            } else {
                this.inforData = item.additional_details;
            }
            this.$modal.show("inforPopup_"+this.modal_key);
        }, 
    },
    updated() {
    },
}
</script>

<style>
    .link {
        cursor: pointer;
    }
    .gift-category__image {
        width: 187px;
        height: 78px;
    }
    .gift-category__img-wrapper {
        line-height: 38px;
    }
    .gift-category__img {
        max-width: 80%;
        vertical-align: middle;
    }
    .gift-category__product-name {
        padding: 0 12px;
        position: absolute;
        top: 45px;
        font-size: 24px;
        font-weight: bold;
    }
    .valid-day {
        position: absolute;
        bottom: 3px;
        padding: 0 5px;
        color: #fff;
    }
    .gift-category__unit-caption {
        bottom: 24px;
    }
    .category__text {
        max-width: 90%;
        height: 24px;
    }
</style>

<style lang="scss" scoped>
.category__link {
    font-size: 18px;
    white-space: nowrap;
}
.no-vat-remark {
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 24px;
  color: #fff;
}
.infor-icon {
  position: absolute;
  top: 13px;
  left: 55px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}
</style>